:root {
  --mobile-width: 95%;
  --tablet-width: 90%;
  --laptop-width: 80%;
  --mobile-breakpoint: 600px;
  --tablet-breakpoint: 900px;
  --laptop-breakpoint: 900px;

  --section-margin: 60px;
  --heading-margin: 50px;
  --heading-margin-sm: 30px;

  --background-color: #fefefe;
  --primary-color: #00aeb5;
  /* --primary-color: hsl(89 90% 45% / 1);; */
  --primary-light-color: #00c7cf;
  --primary-dark-color: #00889b;
  /* --secondary-color: #ff6169; */
  --secondary-color: #f9b147;
  --secondary-light-color: #fbd091;
  --secondary-dark-color: #f79c16;
  --accent-color: #e94c4f;
  --accent-light-color: #f19092;
  --accent-dark-color: #e31f22;
  --text-color: #002140;
  --text-light-color: #72767e;
  --text-white-color: white;
  --gray-color: #a3a3a3;
  --gray-light-color: #c6c7c7;
  --blue-color: #1976d2;
  --blue-dark-color: #1168be;
  --green-dark-color: #85ce33;
  --green-color: #93c65a;
  --green-light: #c0e08e;
  --orange-color: #f38f3c;
  --yellow-color: #f9f4eb;
  --shadow: #bdbdbd;
  --shadow-light: #e2e2e2;
  --radius: 12px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html {
  /* font-size: 62.5%; */
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
body {
  font-family: "Lato", sans-serif;
  background: var(--background-color);
  color: black;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
a {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: var(--text-color);
}
h1 {
  font-size: 3.5rem;
  font-weight: 700;
}
h2 {
  font-size: 2.8rem;
}
h3 {
  font-size: 1.6rem;
  font-weight: 600;
}
h4 {
  font-size: 1.2rem;
  font-weight: 600;
}
h5 {
  font-size: 1.1rem;
  font-weight: 500;
}
p {
  font-size: 1.1rem;
  color: var(--text-light-color);
}
li,
button,
input,
select,
textarea {
  font-size: 1rem;
  color: var(--text-light-color);
  line-height: 1.2;
}
span,
a {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 500;
}
label {
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 500;
}

button {
  cursor: pointer;
  outline: none;
  color: white;
  transition: all 0.15s ease-in-out;
  background-color: var(--primary-color);
  border-radius: 24px;
  padding: 10px 16px;
  min-width: 120px;
  max-height: 38px;
  border: none;
  touch-action: pinch-zoom;
}
button:hover {
  background-color: var(--primary-dark-color);
}
.secondary-btn {
  background-color: var(--secondary-color);
}
.secondary-btn:hover {
  background-color: var(--secondary-dark-color);
}
.accent-btn {
  background-color: var(--accent-color);
}
.accent-btn:hover {
  background-color: var(--accent-dark-color);
}
.gray-btn,
.blue-btn,
.small-btn {
  min-width: 50px;
  padding: 6px 12px;
  border-radius: 24px;
}
.gray-btn {
  color: var(--gray-color);
  background-color: transparent;
  border: 1px solid var(--gray-color);
}
.gray-btn:hover {
  color: var(--text-light-color);
  border-color: var(--text-light-color);
  background-color: transparent;
}
.blue-btn {
  background-color: var(--blue-color);
  border: 1px solid var(--blue-color);
}
.blue-btn:hover {
  background-color: var(--blue-dark-color);
}
.icon {
  padding: 0;
  min-width: 30px;
  background-color: transparent;
  color: gray;
  transition: all 0.1s ease-in-out;
}
.icon:hover {
  transform: scale(1.1);
  background-color: transparent;
}
input {
  outline: none;
  border-radius: 50px;
  color: var(--text-color);
  padding: 6px 14px;
  border: 2px solid var(--text-light-color);
  height: 38px;
}
input:focus {
  border-color: var(--primary-color);
}
input::placeholder {
  color: var(--text-light-color);
}
textarea {
  font-family: "Lato", sans-serif;
  color: var(--text-color);
}
textarea::placeholder {
  font-family: "Lato", sans-serif;
}
input:focus,
select:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline-color: var(--primary-color);
}
a {
  text-decoration: none;
  cursor: pointer;
}
select {
  border-radius: 12px;
  padding: 10px 14px;
}
li {
  list-style: none;
  /* cursor: pointer; */
}

/* svg {
  transition: all 0.1s ease-in-out !important;
}
svg:hover {
  transform: scale(1.2);
} */
#mobile-error {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 600px) {
  :root {
    --section-margin: 50px;
    --heading-margin: 30px;
    --tablet-width: 95%;
    --laptop-width: 95%;
  }
  h1 {
    font-size: 2.4rem;
  }
  h2 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  p,
  li,
  a,
  button,
  input,
  h5,
  select,
  span,
  label,
  textarea {
    font-size: 0.9rem;
  }
  button {
    padding: 8px 12px;
    min-width: 100px;
  }
  input,
  select {
    height: 36px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  :root {
    --section-margin: 50px;
    --heading-margin: 30px;
  }
  h1 {
    font-size: 2.6rem;
  }
  h2 {
    font-size: 2.4rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  p,
  li,
  a,
  button,
  input,
  h5,
  select,
  span,
  label,
  textarea {
    font-size: 1rem;
  }
  button {
    padding: 8px 12px;
    min-width: 100px;
  }
}
